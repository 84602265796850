import axios from "axios";

function setTotalProducts(count) {
  if (count) {
    const el = document.querySelector('.slide-item[href="/admin/products"] span')
    el.innerHTML = `محصولات (${count})`
  }
}

export default {
  async loadInitData({commit, state}) {
    const response = await axios.get('admin/home')
    commit('setSettingGroups', response.data.data.setting_groups);
    commit('setAdmin', response.data.data.admin);
    commit('setNotifications',response.data.data.notifications);
    commit('setTotalUnreadNotifications', response.data.data.total_unread_notifications);
    commit('setTotalUnreadContacts', response.data.data.total_unread_contacts);
    commit('setContacts', response.data.data.contacts);
    let sG = response.data.data.setting_groups;
    commit('setPermissions', response.data.data.permissions)
    sG.forEach((g) => {
      g.title = g.label
      delete g.label
      g.to = '/admin/settings/' + g.name
      g.children = []
      g.permissions = ['public']
    })
    commit('addToMenu', {title:'تنظیمات' , icon:'fe fe-sliders', to:'' , children: sG , permissions:['read_setting']} );

    setTimeout(() => {
      setTotalProducts(response.data.data.total_products)
    })
  },
  showLoading({commit, state}, onlyWhenNotInitialized = true) {
    if (onlyWhenNotInitialized && this.getters.initialized) {
      return;
    }
    commit('incrementLoading')
  },
  hideLoading({commit,state}, onlyWhenNotInitialized = true) {
    if (onlyWhenNotInitialized && this.getters.initialized && state.loading === 0) {
      return;
    }
    commit('decrementLoading')
  },

}
