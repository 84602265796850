import Vue from "vue";

window.clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

Vue.prototype.console = {
    log: (value) => {
        console.log(value)
    }
}