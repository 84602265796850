// test ////
export default [
  {
    path: "/admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@@/core/pages/Index/Index"),
    meta: {
      title: "پنل مدیریت",
    },
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/Dashboard/Dashboard"
          ),
        meta: {
          title: "پنل مدیریت",
        },
      },
      {
        path: "/admin/color",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@@/core/pages/Color/Color"),
        meta: {
          title: "رنگ ",
        },
        props: {
          default: true,
          page: (route) => ({ search: route.query.q }),
        },
      },
      {
        path: "/admin/size-chart",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/sizeChart/SizeChart/SizeChart"
          ),
        meta: {
          title: "سایز چارت",
        },
      },
      {
        path: "/admin/size-chart/edit/:id?",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/sizeChart/SizeChartCreateEdit/SizeChartCreateEdit"
          ),
        meta: {
          title: "ویرایش سایز چارت",
        },
        name: "size-chart",
      },
      {
        path: "/admin/size-chart/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/sizeChart/SizeChartCreateEdit/SizeChartCreateEdit"
          ),
        meta: {
          title: "ثبت سایز چارت",
        },
      },
      {
        path: "/admin/agents",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/agents/agents.vue"
          ),
        meta: {
          title: "نمایندگان",
        },
      },
      {
        path: "/admin/size-chart-type",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/sizeChart/SizeChartType/SizeChartType"
          ),
        meta: {
          title: "انواع سایز چارت",
        },
      },
      {
        path: "/admin/provinces",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/area/Provinces/Provinces"
          ),
        meta: {
          title: "استان",
        },
      },
      {
        path: "/admin/cities",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/area/Cities/Cities"
          ),
        meta: {
          title: "شهر",
        },
      },
      {
        path: "/admin/brands",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@@/core/pages/Brand/Brand"),
        meta: {
          title: "برند",
        },
      },
      {
        path: "/admin/unit",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@@/core/pages/Unit/Unit"),
        meta: {
          title: "واحد",
        },
      },
      {
        path: "/admin/attributes",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/Attribute/Attribute"
          ),
        meta: {
          title: "ویژگی",
        },
      },
      {
        path: "/admin/specifications",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/specification/Specification/Specification"
          ),
        meta: {
          title: "مشخصات",
        },
      },
      {
        path: "/admin/specifications/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/specification/SpecificationCreateEdit/SpecificationCreateEdit"
          ),
        meta: {
          title: "ثبت مشخصات",
        },
      },
      {
        path: "/admin/specifications/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/specification/SpecificationCreateEdit/SpecificationCreateEdit"
          ),
        meta: {
          title: "ویرایش مشخصات",
        },
        name: "specification.edit",
      },
      {
        path: "/admin/categories",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/category/Category/Category"
          ),
        meta: {
          title: "دسته بندی",
        },
      },
      {
        path: "/admin/categories/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/category/CategoryCreateEdit/CategoryCreateEdit"
          ),
        meta: {
          title: "ثبت دسته بندی",
        },
      },
      {
        path: "/admin/categories/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/category/CategoryCreateEdit/CategoryCreateEdit"
          ),
        meta: {
          title: "ویرایش دسته بندی",
        },
        name: "category.edit",
      },
      {
        path: "/admin/posts",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/post/Post/Post"
          ),
        meta: {
          title: "پست",
        },
      },
      {
        path: "/admin/posts/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/post/PostCreateEdit/PostCreateEdit"
          ),
        meta: {
          title: "ثبت پست",
        },
      },
      {
        path: "/admin/posts/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/post/PostCreateEdit/PostCreateEdit"
          ),
        meta: {
          title: "ویرایش پست",
        },
        name: "post.edit",
      },
      {
        path: "/admin/post-categories",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/post/PostCategories/PostCategories"
          ),
        meta: {
          title: "دسته بندی پست",
        },
      },
      {
        path: "/admin/post-comment/comment/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/post/PostComments/PostComments"
          ),
        meta: {
          title: "نظرات پست",
        },
        name: "show.comment",
      },
      {
        path: "/admin/post-comment/:post?",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/post/PostComments/PostComments"
          ),
        meta: {
          title: "نظرات پست",
        },
        name: "post.comment",
      },
      {
        path: "/admin/settings/:group",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/Settings/Settings"
          ),
        meta: {
          title: "تنظیمات",
        },
      },
      {
        path: "/admin/slider",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/slider/SliderGroup/SliderGroup"
          ),
        meta: {
          title: "اسلایدر",
        },
      },
      {
        path: "/admin/slider/:group",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/slider/Slider/Slider"
          ),
        meta: {
          title: "گروه اسلایدر",
        },
        name: "slider",
      },
      // agents slider

      {
        path: "/admin/agents-slider",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/agentsSlider/SliderGroup/SliderGroup"
          ),
        meta: {
          title: "اسلایدر",
        },
      },
      {
        path: "/admin/agents-slider/:group",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/agentsSlider/Slider/Slider"
          ),
        meta: {
          title: "گروه اسلایدر",
        },
        name: "agents-slider",
      },
      {
        path: "/admin/menu",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/menu/MenuGroup/MenuGroup"
          ),
        meta: {
          title: "منو",
        },
      },
      {
        path: "/admin/menu/:group",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/menu/Menu/Menu"
          ),
        meta: {
          title: "منو",
        },
        name: "menu",
      },
      {
        path: "/admin/coupon",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@@/core/pages/Coupon/Coupon"),
        meta: {
          title: "کد تخفیف",
        },
      },
      {
        path: "/admin/discount",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/discount/discountList/discountList"
          ),
        meta: {
          title: " تخفیف ویژه",
        },
      },
      {
        path: "/admin/discount-item/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/discount/discountType/discountType"
          ),
        meta: {
          title: " جزئیات تخفیف ویژه",
        },
      },
      {
        path: "/admin/discount-config/list/:item/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/discount/list/list"
          ),
        meta: {
          title: " جزئیات تخفیف ویژه",
        },
      },
      {
        path: "/admin/discount-config/item/:item/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/discount/discount/discount"
          ),
        meta: {
          title: " آیتم تخفیف ویژه",
        },
      },
      {
        path: "/admin/discount-config/item/:item/:id/:edit",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/discount/discount/discount"
          ),
        meta: {
          title: " آیتم تخفیف ویژه",
        },
      },

      {
        path: "/admin/calc-unit-price",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/calcUnitPrice/calcUnitPrice"
          ),
        meta: {
          title: " تخفیف ویژه",
        },
      },

      {
        path: "/admin/shipping",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/shipping/Shipping/Shipping"
          ),
        meta: {
          title: "حمل و نقل",
        },
      },
      {
        path: "/admin/shipping/:id/ranges",
        name: "shipping.ranges",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/shipping/ShippingRanges"
          ),
        meta: {
          title: "بازه حمل و نقل",
        },
      },
      {
        path: "/admin/shipping/cities/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/shipping/ShippingCities/ShippingCities"
          ),
        meta: {
          title: "حمل و نقل",
        },
        name: "shipping.cities",
      },
      {
        path: "/admin/customers",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/customer/Customers/Customers"
          ),
        meta: {
          title: "نمایندگان",
        },
      },
      {
        path: "/admin/customers/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/customer/CustomersCreateEdit/CustomersCreateEdit"
          ),
        meta: {
          title: "ثبت نماینده",
        },
      },
      {
        path: "/admin/customers/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/customer/CustomersCreateEdit/CustomersCreateEdit"
          ),
        meta: {
          title: "ویرایش نماینده",
        },
        name: "customer.edit",
      },
      {
        path: "/admin/customers/show/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/customer/CustomerShow/CustomerShow"
          ),
        meta: {
          title: "مشاهده نماینده",
        },
        name: "customer.show",
      },
      {
        path: "/admin/users/show/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/users/userShow/userShow"
          ),
        meta: {
          title: "مشاهده کاربر",
        },
        name: "user.show",
      },
      {
        path: "/admin/admins",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@@/core/pages/Admin/Admin"),
        meta: {
          title: "مدیران",
        },
      },
      {
        path: "/admin/roles",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@@/core/pages/Roles/Roles"),
        meta: {
          title: "نقش ها",
        },
      },
      {
        path: "/admin/products",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/product/Products/Products"
          ),
        meta: {
          title: "لیست محصولات",
        },
      },
      {
        path: "/admin/products/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/product/ProductCreateEdit/ProductCreateEdit"
          ),
        meta: {
          title: "ایجاد محصول",
        },
      },
      {
        path: "/admin/store",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@@/core/pages/Store/Store"),
        meta: {
          title: "انبار",
        },
      },

      {
        path: "/admin/users",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/users/users/users"
          ),
        meta: {
          title: "کاربران معمولی",
        },
      },
      {
        path: "/admin/users/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/users/createEdit/createEdit"
          ),
        meta: {
          title: "ساخت کاربران معمولی",
        },
      },
      {
        path: "/admin/users/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/users/createEdit/createEdit"
          ),
        meta: {
          title: "ویرایش کاربران معمولی",
        },
      },
      {
        path: "/admin/store-transfer",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/Store/storeTransfer/storeTransfer"
          ),
        meta: {
          title: "جایگزینی",
        },
      },
      {
        path: "/admin/store-transfer/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/Store/storeTransfer/create/create"
          ),
        meta: {
          title: "حواله انبار",
        },
      },

      {
        path: "/admin/store-transfer/location",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/Store/storeTransferLocation/storeTransferLocation"
          ),
        meta: {
          title: "مکان انبار",
        },
      },

      {
        path: "/admin/store-transfer/print/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/Store/storeTransfer/print/print"
          ),
        meta: {
          title: "کنترل و پرینت حواله انبار",
        },
      },
      {
        path: "/admin/store/transaction-management",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/Store/TransactionManagement/TransactionManagement"
          ),
        meta: {
          title: "تعیین وضعیت انبار",
        },
      },
      {
        path: "/admin/products/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/product/ProductCreateEdit/ProductCreateEdit"
          ),
        meta: {
          title: "ویرایش محصول",
        },
        props: true,
      },
      {
        path: "/admin/comments",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/product/ProductComment/ProductComment"
          ),
        meta: {
          title: "نظرات محصول",
        },
        props: true,
      },
      {
        path: "/admin/order",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/order/Orders/Orders"
          ),
        meta: {
          title: "سفارشات",
        },
      },
      {
        path: "/admin/order/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/order/OrderCreateEdit/OrderCreateEdit"
          ),
        meta: {
          title: "ثبت سفارش",
        },
        name: "order.create",
      },
      {
        path: "/admin/order/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/order/OrderDetail/OrderDetail"
          ),
        meta: {
          title: "جزئیات سفارش",
        },
        name: "order.detail",
      },
      {
        path: "/admin/virtual-order/detail/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/virtualOrders/OrderDetail/OrderDetail"
          ),
        meta: {
          title: "جزئیات سفارش",
        },
        name: "virtual.detail",
      },
      {
        path: "/admin/disposable-password",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/disposablePassword/disposablePassword.vue"
          ),
        meta: {
          title: "مدیریت رمز یکبار مصرف",
        },
        name: "order.detail",
      },
      //
      {
        path: "/admin/quntity-varieties",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/quntity/quntityVarieties"
          ),
        meta: {
          title: "تنوع ها",
        },
      },
      {
        path: "/admin/flash",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/flash/Flash/Flash"
          ),
        meta: {
          title: "کمپین فروش",
        },
      },
      {
        path: "/admin/flash/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/flash/FlashCreateEdit/FlashCreateEdit"
          ),
        meta: {
          title: "ثبت کمپین",
        },
      },
      {
        path: "/admin/flash/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/flash/FlashCreateEdit/FlashCreateEdit"
          ),
        meta: {
          title: "ویرایش کمپین",
        },
        name: "flash.edit",
      },
      {
        path: "/admin/instagram",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/Instagram/Instagram"
          ),
        meta: {
          title: "اینستاگرام",
        },
      },
      {
        path: "/admin/faqs",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@@/core/pages/Faq/Faq"),
        meta: {
          title: "سوالات متداول",
        },
      },
      {
        path: "/admin/position",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/advertise/Position/Position"
          ),
        meta: {
          title: "جایگاه بنر",
        },
      },
      {
        path: "/admin/position/advertisements/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/advertise/PositionAdvertisements/PositionAdvertisements"
          ),
        meta: {
          title: "جایگاه بنر",
        },
        name: "position.advertisements",
      },
      {
        path: "/admin/advertise",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/advertise/Advertise/Advertise"
          ),
        meta: {
          title: "بنر",
        },
      },
      {
        path: "/admin/newsletters",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/newsletters/Newsletters/Newsletters"
          ),
        meta: {
          title: "خبرنامه",
        },
      },
      {
        path: "/admin/newsletters/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/newsletters/NewslettersCreate/NewslettersCreate"
          ),
        meta: {
          title: "ثبت خبرنامه",
        },
      },
      {
        path: "/admin/newsletters/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/newsletters/NewslettersShow/NewslettersShow"
          ),
        meta: {
          title: "مشاهده خبرنامه",
        },
        name: "newsletters.show",
      },
      {
        path: "/admin/member-newsletters",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/newsletters/Member/Member"
          ),
        meta: {
          title: "اعضا خبرنامه",
        },
      },
      {
        path: "/admin/pages",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/page/Pages/Pages"
          ),
        meta: {
          title: "لیست صفحات",
        },
      },
      {
        path: "/admin/pages/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/page/PageCreateEdit/PageCreateEdit"
          ),
        meta: {
          title: "ایجاد صفحه جدید",
        },
      },
      {
        path: "/admin/pages/:id/edit",
        name: "page.edit",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/page/PageCreateEdit/PageCreateEdit"
          ),
        meta: {
          title: "ویرایش صفحه",
        },
      },
      {
        path: "/admin/contact",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/Contact/Contact"
          ),
        meta: {
          title: "تماس با ما",
        },
      },
      {
        path: "/admin/sets",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@@/core/pages/sets/Set/Set"),
        meta: {
          title: "ست ها",
        },
      },
      {
        path: "/admin/sets/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/sets/SetCreateEdit/SetCreateEdit"
          ),
        meta: {
          title: "ثبت ست",
        },
      },
      {
        path: "/admin/sets/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/sets/SetCreateEdit/SetCreateEdit"
          ),
        meta: {
          title: "ویرایش ست",
        },
        name: "set.edit",
      },
      {
        path: "/admin/recommendation-groups",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/recommendations/RecommendationGroups/RecommendationGroups"
          ),
        meta: {
          title: "محصولات پیشنهادی",
        },
      },
      {
        name: "recommendation",
        props: true,
        path: "/admin/recommendation/:group",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/recommendations/Recommendation/Recommendation"
          ),
        meta: {
          title: "مرتب سازی لیست محصولات",
        },
      },
      {
        name: "gift",
        props: true,
        path: "/admin/gifts",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/gift/Gift/Gift"
          ),
        meta: {
          title: "هدایا",
        },
      },

      {
        path: "/admin/accounting/orders-report",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/accounting/ordersReport/ordersReport"
          ),
        meta: {
          title: "گزارش حسابداری محصولات",
        },
      },
      {
        path: "/admin/accounting/product-report",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/accounting/productReport/productReport"
          ),
        meta: {
          title: "گزارش حسابداری محصولات",
        },
      },
      {
        path: "/admin/accounting/seller-report",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/accounting/sellerReport/sellerReport"
          ),
        meta: {
          title: "گزارش حسابداری فروشندگان",
        },
      },

      {
        path: "/admin/accounting/virtual-report",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/accounting/virtualAdminReport/virtualAdminReport"
          ),
        meta: {
          title: "گزارش فروشندگان مجازی",
        },
      },
      {
        path: "/admin/shop/report",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/physicalShop/report/report"
          ),
        meta: {
          title: "گزارش ریز اقلام",
        },
      },
      {
        path: "/admin/accounting/cashier-report",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/accounting/cashierReport/cashierReport"
          ),
        meta: {
          title: "گزارش صندوقداران",
        },
      },
      {
        path: "/admin/reports/renew-subscription",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/RenewSubscription/RenewSubscription"
          ),
        meta: {
          title: "گزارش کاربران",
        },
      },
      {
        path: "/admin/reports/mini-orders",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/miniOrders/miniOrders"
          ),
        meta: {
          title: "گزارش فروش حضوری",
        },
      },
      {
        path: "/admin/reports/store",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/store/store"
          ),
        meta: {
          title: "گزارش انبار",
        },
      },

      {
        path: "/admin/reports/virtual-orders",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/virtualOrdersReports/virtualOrdersReports"
          ),
        meta: {
          title: "گزارش سفارشات فروشگاه مجازی",
        },
      },
      {
        path: "/admin/reports/virtual-orders-product",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/virtualOrdersProductReports/virtualOrdersProductReports"
          ),
        meta: {
          title: "گزارش محصولات فروشگاه مجازی",
        },
      },

      {
        path: "/admin/reports/orders-profits",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/orderProfits/orderProfits"
          ),
        meta: {
          title: "گزارش سود سفارشات",
        },
      },
      {
        path: "/admin/reports/orders-profits-by-product",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/orderProfitsByProduct/orderProfitsByProduct"
          ),
        meta: {
          title: "گزارش سود  محصول",
        },
      },
      {
        path: "/admin/reports/customers",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/CustomersReport/CustomersReport"
          ),
        meta: {
          title: "گزارش کاربران",
        },
      },
      {
        path: "/admin/reports/products",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/ProductsReport/ProductsReport"
          ),
        meta: {
          title: "گزارش محصولات",
        },
      },
      {
        path: "/admin/reports/varieties",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/VarietiesReport/VarietiesReport"
          ),
        meta: {
          title: "گزارش تنوع ها",
        },
      },
      {
        path: "/admin/reports/wallets",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/WalletReport/WalletReport"
          ),
        meta: {
          title: "گزارش کیف پول",
        },
      },
      {
        path: "/admin/reports/orders",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/report/OrdersReport/OrdersReport"
          ),
        meta: {
          title: "گزارش سفارشات(تکی)",
        },
      },
      {
        path: "/admin/shop",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/physicalShop/Shop"
          ),
        meta: {
          title: "فروشگاه حضوری",
        },
      },
      {
        path: "/admin/shop/orders",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/physicalShop/ShopIndex"
          ),
        meta: {
          title: "لیست فروش حضوری",
        },
      },
      {
        path: "/admin/shop/orders/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/physicalShop/ShopShow"
          ),
        meta: {
          title: "مشاهده سفارش حضوری",
        },
      },
      {
        path: "/admin/shop/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/physicalShop/ShopCreate"
          ),
        meta: {
          title: "ثبت خرید",
        },
      },
      {
        path: "/admin/virtual-order",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/virtualOrders/virtualOrders/virtualOrders"
          ),
        meta: {
          title: "فروشگاه مجازی",
        },
      },
      {
        path: "/admin/accounting/virtual-order",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/accounting/virtualOrders/virtualOrders"
          ),
        meta: {
          title: "تایید فروشگاه مجازی",
        },
      },
      {
        path: "/admin/virtual-order/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/virtualOrders/create/create"
          ),
        meta: {
          title: "ساخت سفارش",
        },
      },
      {
        path: "/admin/customer_roles",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/customerRole/CustomerRole"
          ),
        meta: {
          title: "نقش نمایندگان",
        },
      },
      // {
      //   path: "/admin/sellers",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "admin" */ "@@/core/pages/seller/Seller/Seller"
      //     ),
      //   meta: {
      //     title: "فروشندگان",
      //   },
      // },
      {
        path: "/admin/transactions",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@@/core/pages/Wallet/Wallet"),
        meta: {
          title: "تراکنش های کیف پول",
        },
      },
      {
        path: "/admin/sellers",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/sellers/sellers/sellers"
          ),
        meta: {
          title: "لیست بازاریاب ها",
        },
      },
      {
        path: "/admin/sellers/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/sellers/createEdit/createEdit"
          ),
        meta: {
          title: "ساخت بازاریاب",
        },
      },
      {
        path: "/admin/sellers/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/sellers/createEdit/createEdit"
          ),
        meta: {
          title: "ویرایش بازاریاب",
        },
      },
      // providers

      {
        path: "/admin/providers",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/providers/providers/providers"
          ),
        meta: {
          title: "لیست تامین کننده ها",
        },
      },
      {
        path: "/admin/providers/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/providers/createEdit/createEdit"
          ),
        meta: {
          title: "ساخت تامین کننده",
        },
      },
      {
        path: "/admin/providers/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/providers/createEdit/createEdit"
          ),
        meta: {
          title: "ویرایش تامین کننده",
        },
      },
      // providers
      {
        path: "/admin/withdraws",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/withdraw/Withdraw"
          ),
        meta: {
          title: "برداشت های کیف پول",
        },
      },
      
      {
        path: "/admin/htaccess",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/Htaccess/Htaccess"
          ),
        meta: {
          title: "htaccess",
        },
      },
    ],
  },
  {
    path: "/admin/login",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@@/core/pages/auth/Login/Login"),
    meta: {
      title: "ورود به پنل مدیریت",
    },
  },
  {
    path: "/admin/print-orders",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@@/core/pages/order/OrderPrint/OrderPrint"
      ),
    meta: {
      title: "چاپ فاکتور ها",
    },
    name: "orders.print",
  },
  {
    path: "/admin/print-barcodes",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@@/core/pages/barcode/barcode"),
    meta: {
      title: "چاپ فاکتور ها",
    },
    name: "orders.print",
  },
];
