import Vuex from "vuex";
import Vue from "vue";
import adminModule from '@@/core/store'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        admin: adminModule,
    }
})

export default store;
